'use client'
import { useEffect, useRef } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import {
  initArrowPositionForListSelection,
  initSliderForListSelection,
} from '../../utils/ListSelection.utils'
import { GtmProduct, gtmPush } from '../../utils/gtmHelpers'
import { handleError } from '../../../core/hook/utils'

export default function ListSelectionSwiper(props: {
  parentClassName?: string
  centerInsufficientSlides?: boolean
  items: GtmProduct[]
  children: React.ReactNode
}) {
  const { items, parentClassName, centerInsufficientSlides, children } = props
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0,
    freezeOnceVisible: true,
  })
  const swiperElemRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!isIntersecting) {
      return
    }
    const swiper = initSliderForListSelection(
      parentClassName,
      centerInsufficientSlides,
      {
        init: false,
      },
      swiperElemRef.current,
    )

    initArrowPositionForListSelection()

    function pushViewItemListGtmEvent() {
      const slideList = swiper.el.querySelectorAll<HTMLDivElement>('.swiper-slide-visible')
      const gtmItems: GtmProduct[] = []
      for (let i = 0; i < slideList.length; i += 1) {
        const slideEl = slideList[i]
        const cardEl = slideEl.querySelector<HTMLDivElement>('.card') as HTMLDivElement
        const p = items.find((item) => item.item_id === cardEl.dataset.productSku)
        if (!p) {
          continue
        }
        gtmItems.push(p)
      }
      if (gtmItems.length > 0) {
        gtmPush({
          event: 'view_item_list',
          ecommerce: {
            item_list_id: gtmItems[0].item_list_id,
            item_list_name: gtmItems[0].item_list_name,
            items: gtmItems,
          },
        })
      }
    }

    if (Array.isArray(swiper)) {
      return
    }

    swiper.on('init', function () {
      pushViewItemListGtmEvent()
    })

    swiper.on('slideChange', function () {
      pushViewItemListGtmEvent()
    })

    swiper.init()

    return () => {
      try {
        swiper.destroy?.()
      } catch (error) {
        // TODO workaround
        handleError(error, false)
      }
    }
  }, [parentClassName, centerInsufficientSlides, isIntersecting])
  return (
    <div
      className='swiper list-selection-swiper'
      ref={(elem) => {
        ref(elem)
        swiperElemRef.current = elem
      }}>
      <div className='swiper-wrapper list-selection-swiper-wrapper'>{children}</div>
    </div>
  )
}
