import { useApolloClient } from '@apollo/client'
import { ProductsStockDocument, ProductsStockQuery } from '../../queries/ProductsStock.gql'
import { useQuery } from '@tanstack/react-query'
import useCurrentStore from './useCurrentStore'

const productStockStatusFetcher = {
  isFetching: false,
  queue: [] as string[],
  fetchId: 0,
  fetchDoneListeners: [] as ((result: ProductsStockQuery) => void)[],
  async doFetch(options: { apolloClient: ReturnType<typeof useApolloClient>, store: { prefix: string } }) {
    window.clearTimeout(this.fetchId)
    this.fetchId = window.setTimeout(async () => {
      const processing = this.queue
      const listeners = this.fetchDoneListeners
      this.queue = []
      this.fetchDoneListeners = []
      const { apolloClient, store } = options
      const result = await apolloClient.query({
        query: ProductsStockDocument,
        context: {
          headers: {
            Store: store.prefix,
          },
        },
        variables: {
          filters: { sku: { in: processing } },
        },
        fetchPolicy: 'no-cache',
      })
      for (const listener of listeners) {
        listener(result.data)
      }
    }, 10)
  },
  async fetchMultiple(options: {
    apolloClient: ReturnType<typeof useApolloClient>
    skus: string[]
    store: { prefix: string },
  }): Promise<NonNullable<NonNullable<ProductsStockQuery['products']>['items']>> {
    const { skus } = options
    for (const sku of options.skus) {
      if (!this.queue.includes(sku)) {
        this.queue.push(sku)
      }
    }
    return new Promise((resolve) => {
      this.fetchDoneListeners.push((result) => {
        const items = result.products?.items
        const filteredItems = [] as NonNullable<typeof items>
        if (!items) {
          resolve(filteredItems)
          return
        }
        const itemsMap = {} as Record<string, (typeof items)[0]>
        for (const item of items) {
          if (item?.sku) {
            itemsMap[item.sku] = item
          }
        }
        for (const sku of skus) {
          if (itemsMap[sku]) {
            filteredItems.push(itemsMap[sku])
          }
        }
        resolve(filteredItems)
      })
      this.doFetch(options)
    })
  },
  async fetchOne(options: {
    apolloClient: ReturnType<typeof useApolloClient>
    sku: string
    store: { prefix: string },
  }): Promise<NonNullable<NonNullable<ProductsStockQuery['products']>['items']>[number]> {
    if (!this.queue.includes(options.sku)) {
      this.queue.push(options.sku)
    }
    return new Promise((resolve) => {
      this.fetchDoneListeners.push((result) => {
        const items = result.products?.items
        if (!items) {
          resolve(null)
          return
        }
        for (const item of items) {
          if (item && item.sku === options.sku) {
            resolve(item)
            return
          }
        }
        resolve(null)
      })
      this.doFetch(options)
    })
  },
}

export function useMultipleProductsStockStatusQuery(options: {
  skus: string[]
  enabled?: boolean
}) {
  const apolloClient = useApolloClient()
  const store = useCurrentStore();
  return useQuery({
    queryKey: ['productStockStatus', options.skus.sort()],
    queryFn() {
      if (options.skus.length === 0) {
        return []
      }
      return productStockStatusFetcher.fetchMultiple({ skus: options.skus, apolloClient, store })
    },
    enabled: options.enabled,
  })
}

export default function useProductStockStatusQuery(options: {
  sku?: string | null
  enabled?: boolean
}) {
  const apolloClient = useApolloClient()
  const store = useCurrentStore();
  return useQuery({
    queryKey: ['productStockStatus', options.sku],
    queryFn() {
      if (!options.sku) {
        return null
      }
      return productStockStatusFetcher.fetchOne({ sku: options.sku, apolloClient, store })
    },
    enabled: options.enabled,
  })
}
