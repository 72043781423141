import { SelectionItem } from '../../../domain/selectionItem'
import { Tag } from '../Tag/tag'
import Image from '../Image/Image'
import PushLink from './PushLink'
import PushSmallPrice from './PustSmallPrice'
import PushSmallSwiper from './PushSmallSwiper'
import { Message } from '../Message/Message'
import PushSmallCta from './PushSmallCta'
import PushSmallCartIcon from './PushSmallCartIcon'
import { createGtmProduct } from '../../utils/gtmHelpers'

export const PushSmall = ({
  id,
  mediaGallery,
  name,
  gtm_name,
  title,
  titleDescription,
  priceRange,
  urlKey,
  parentClassNameList,
  colorVariations,
  isExcluWeb,
  isNew,
  isIconique,
  mariage,
  typename,
  customPrice,
  couleurMetalExtended,
  position,
  gtm_categories,
  sku,
  listId,
  listName,
  isFineJewelry,
  enVente,
}: SelectionItem & {
  position?: number
  isHome?: boolean
  isLoading?: boolean
  listId?: string
  listName?: string
}) => {
  const product = {
    url_key: urlKey,
    price_range: priceRange,
    __typename: typename,
    name,
    gtm_name,
    id,
    sku,
    gtm_categories: gtm_categories,
  } as Product
  const gtmProduct = {
    ...createGtmProduct(product, {
      listId,
      listName,
      position,
    }),
    url_key: urlKey as string,
    type: typename as string,
    enVente: enVente,
    isFineJewelry: isFineJewelry,
  }


  if (mediaGallery && mediaGallery.length > 1) {
    const imageTagSmall = mediaGallery.findIndex((item) => {
      if (item && item.roles) {
        return item.roles.includes('small_image')
      }
      return false
    })

    if (imageTagSmall !== -1) {
      const [smallImage] = mediaGallery.splice(imageTagSmall, 1)
      mediaGallery.unshift(smallImage)
    }
  }

  return (
    <div className='selection-item'>
      <article
        className='card'
        data-product-id={product.id}
        data-product-sku={product.sku}
        data-product-type={product.__typename}
        data-product-name={product.name}
        data-product-price={product.price_range?.minimum_price.regular_price.value?.toString()}
        data-product-category={product.gtm_categories}
        data-product-brand={couleurMetalExtended?.label}
        data-product-position={position}>
        <PushLink listId={listId} listName={listName} position={position} product={gtmProduct}>
          <figure>
            <Tag
              className='hidden lg-block'
              isExcluWeb={isExcluWeb ?? 0}
              isNew={isNew ?? 0}
              isIconique={isIconique ?? 0}
            />

            {mediaGallery && mediaGallery.length > 1 ? (
              <div className='img-container'>
                <PushSmallSwiper
                  id={product.id}
                  allowOnDesktop={(mediaGallery.length || 0) > 1}
                  parentClassNameList={parentClassNameList}>
                  {mediaGallery?.map(
                    (image) =>
                      image?.small_image && (
                        <div className='swiper-slide' key={`slide-${image.url}`}>
                          <PushSmallCartIcon sku={sku} />
                          <Tag
                            className='lg-hidden'
                            isExcluWeb={isExcluWeb ?? 0}
                            isNew={isNew ?? 0}
                            isIconique={isIconique ?? 0}
                          />
                          <picture>
                            <Image src={image.small_image} alt={image.label} size='l' />
                          </picture>
                        </div>
                      ),
                  )}
                </PushSmallSwiper>
              </div>
            ) : (
              <div className='img-container'>
                {mediaGallery?.map((image) => (
                  <picture key={`media-${image.url}`}>
                    <Image src={image.small_image} alt={image.label} size='l' />
                  </picture>
                ))}
              </div>
            )}

            <figcaption>
              {name && <h3 className='title-h5' dangerouslySetInnerHTML={{ __html: name }} />}
              {title && (
                <p className='push-description' dangerouslySetInnerHTML={{ __html: title }} />
              )}
              {titleDescription && (
                <p
                  className='push-description'
                  dangerouslySetInnerHTML={{ __html: titleDescription }}
                />
              )}

              {colorVariations && colorVariations >= 2 ? (
                <p className='push-color'>
                  <Message msgKey='%1 colors' options={{ variables: { 0: colorVariations } }} />
                </p>
              ) : !colorVariations || colorVariations < 2 ? (
                <p className='push-color'>
                  <Message msgKey='1 color' />
                </p>
              ) : null}
              <PushSmallPrice
                mariage={mariage}
                priceRange={product.price_range}
                customPrice={customPrice}
                typename={typename}
              />
            </figcaption>
          </figure>
        </PushLink>
      </article>
      <div className='hidden lg-block'>
        <PushSmallCta
          product={gtmProduct}
          position={position}
          listName={listName}
          listId={listId}
        />
      </div>
    </div>
  )
}
