'use client'
import React, { useContext } from 'react'
import PushLink from './PushLink'
import PushSmallAddToCartBtn from './PushSmallAddToCartBtn'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import useProductStockStatusQuery from '../../../core/hook/useProductStockStatusQuery'
import Loader from '../Loader/Loader'
import { GtmProduct } from '../../utils/gtmHelpers'
import { isSellingProductOnline } from '../../utils/Product.utils'

export default function PushSmallCta(props: {
  product: GtmProduct & {
    url_key: string
    type: string
    enVente?: Product['en_vente']
    isFineJewelry?: boolean
  }
  listId?: string
  listName?: string
  position?: number
}) {
  const { isEcommerce } = useContext(ConfigContext)
  const { listName, listId, position, product } = props
  const t = useTranslation()
  const query = useProductStockStatusQuery({
    sku: product.item_id,
    enabled: isEcommerce(),
  })
  const isSellingOnline = isSellingProductOnline(product, isEcommerce())
  const item = query.data

  const variants = item && 'variants' in item ? (item.variants as Variant[]) : null
  const hasStock = variants
    ? variants.some((v) => v.product?.stock_status === 'IN_STOCK')
    : item?.stock_status === 'IN_STOCK'

  if (!isSellingOnline || !hasStock) {
    return (
      <PushLink
        className='button black'
        product={product}
        listId={listId}
        listName={listName}
        position={position}>
        {t('global-cta-discover')}
      </PushLink>
    )
  }

  if (query.isLoading) {
    return <Loader small />
  }

  return (
    <PushSmallAddToCartBtn
      product={product}
      listId={listId}
      listName={listName}
      position={position}
    />
  )
}
